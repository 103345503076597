.ProjectIndexPage {
	position: absolute;
	height: 100vh;
	width: 100vw;
	z-index: 12;
	background-color: $color-white;
	display: none;
	overflow: auto;
	-webkit-overflow-scrolling: touch;

	.close-overlay-button {
		color: $color-black;
		z-index: 2;
	}

	.filter-sticky {
		position: fixed;
		top: 0;
		left: 0;
		pointer-events: none;
		width: 100%;
		height: 70px;
		background-color: $color-white;
		z-index: 1;
		overflow: hidden;
		transform: translateY(-100%);

		&.visible {
			pointer-events: auto;
		}
	}

	.filter-toggle {
		padding: 10px;
		margin: 20px 0 0 10px;

		&.active {
			svg {
				transform: rotate(135deg);
			}
		}

		svg {
			transition: .3s transform ease-out;
			pointer-events: none;
		}
	}

	.title {
		margin: 130px auto 50px auto;
		display: block;
		text-align: center;

		@media only screen and (min-width: $media-breakpoint-md) {
			margin: 100px auto 50px auto;
		}

		@media only screen and (min-width: $media-breakpoint-xxl) {
			margin: 150px auto 80px auto;
		}
	}

	.categories-menu {
		text-align: center;
		margin-bottom: 50px;

		.categories-list {
			display: none;

			@media only screen and (min-width: $media-breakpoint-md) {
				display: inline-flex;
			}
		}

		li {
			a {
				opacity: .4;
				text-decoration: none;

				&.active {
					opacity: 1;
					text-decoration: underline;
				}

				&:hover {
					opacity: 1;
				}
			}
		}

		a {
			@extend .type-title--body;
			padding: 20px;
		}
	}

	.filter {
		display: none;

		&.active {
			display: block;
		}

		ul {
			display: flex;
			list-style: none;

			li {
				a {
					padding: 10px;
					color: #000000;
				}
			}
		}

		&:nth-child(1) { z-index: 16; }
		&:nth-child(2) { z-index: 15; }
		&:nth-child(3) { z-index: 14; }
		&:nth-child(4) { z-index: 13; }
		&:nth-child(5) { z-index: 12; }
		&:nth-child(6) { z-index: 11; }

		&.categories {
			display: block;

			@media only screen and (min-width: $media-breakpoint-md) {
				display: none;
			}

			.Dropdown {
				position: relative;
				z-index: 17;
			}
		}

		&.search {
			z-index: 1;
		}
	}

	.filter-sticky {
		.categories-menu {
			margin-bottom: 0;
		}
	}

	.filters-wrapper {
		padding-bottom: 50px;

		@media only screen and (min-width: $media-breakpoint-xxl) {
			padding-bottom: 120px;
		}
	}

	.dropdowns-wrapper {
		display: flex;
		grid-column-start: 2;
		grid-column-end: span 10;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		@media only screen and (min-width: $media-breakpoint-lg) {
			flex-direction: row;
		}

		.filter {
			position: relative;
			margin: 10px 10px;
			width: 100%;

			@media only screen and (min-width: $media-breakpoint-lg) {
				width: auto;
			}
		}

		.dropdown-wrapper {
			width: 100%;

			@media only screen and (min-width: $media-breakpoint-lg) {
				width: auto;
			}
		}
	}

	.results-container {
		.grid {
			max-width: $media-breakpoint-xxl;
		}

		.contents {
			display: flex;
			flex-wrap: wrap;
			grid-column-start: 1;
			grid-column-end: span 12;

			.heading {
				width: 100%;
				font-size: 30px;
				margin-bottom: 25px;
				font-weight: 500;

				@media only screen and (min-width: $media-breakpoint-sm) {
					padding: 0 10px;
				}
			}

			.project-index-item {
				width: 100%;
				padding: 0;
				margin-bottom: 40px;

				&.show-award {
					.award {
						display: block;
					}
				}

				@media only screen and (min-width: $media-breakpoint-sm) {
					width: 33%;
					margin-bottom: 80px;
					padding: 0 10px;
				}

				@media only screen and (min-width: $media-breakpoint-xl) {
					width: 25%;
				}

				a {
					display: block;
				}

				img {
					width: 100%;
					opacity: 0;
					transition: opacity .4s;
					display: block;

					&.lazyloaded {
						opacity: 1;
					}

					&:hover {
						opacity: .9;
					}
				}

				.media-wrapper {
					background-color: $color-black;
					overflow: hidden;
					position: relative;
				}

				.info-wrapper {
					position: relative;
					z-index: -1;
					margin-top: 15px;

					@media only screen and (min-width: $media-breakpoint-md) {
						margin-top: 26px;
					}
				}

				span {
					margin: 0;
					margin-bottom: 3px;
					text-align: left;
					display: block;
					@extend .type-title--small;

					&.title {
						@media only screen and (min-width: $media-breakpoint-xl) {
							padding-right: 10%;
						}
					}

					&.student {
						color: $color-gray;
					}

					&.subtitle {
						font-weight: 300;
						color: $color-gray;
					}
				}

				.award {
					position: absolute;
					top: 14px;
					right: 0;
					display: none;
					font-size: 9px;
					font-weight: 500;
					text-transform: uppercase;
					background: $color-black;
					color: $color-white;
					padding: 5px 12px 4px 9px;
					line-height: 1;
				}
			}
		}
	}
}
