.IntroPage {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #131214;
	//opacity: 0;
	z-index: 26;
	//display: none;

	.flex-wrapper {
		display: flex;
		width: 100%;
		height: 100%;
		justify-content:center;
		align-items: center;
		flex-direction: column;
	}

	.load-screen {
		text-align: center;
		max-width: 860px;
	}

	.intro-animation {
		width: 100%;
		margin-bottom: 15px;

		@media only screen and (min-width: $media-breakpoint-md) {
			margin-bottom: 20px;
		}
	}

	.loader {
		width: 200px;
		margin: 26px auto 0 auto;
		height: 3px;
		transform-origin: center;

		@media only screen and (min-width: $media-breakpoint-md) {
			margin: 42px auto 0 auto;
			width: 250px;
		}

		.mask {
			width: 0px;
			height: 100%;
			overflow: hidden;
			position: relative;
		}

		.bar {
			position: absolute;
			width: 250px;
			height: 3px;
			background: linear-gradient(90deg, $color-white 0%,  $color-background 50%, $color-white 100%);
		}
	}

	.title {
		font-weight: 500;
		line-height: 1.2;
		margin-bottom: 10px;
		max-width: 220px;
		@include fluid-type(font-size, $media-breakpoint-xsm, $media-breakpoint-xxl, 22px, 60px);

		@media only screen and (min-width: $media-breakpoint-md) {
			max-width: none;
			padding: 0 30px;
			margin-bottom: 20px;
		}
	}

	.title,
	.type-title--small {
		color: $color-white;
	}

	.startButton {
		margin-top: 30px;
		margin-left: auto;
		margin-right: auto;
		visibility: hidden;
		color: $color-white;
		text-align: center;
		font-weight: 500;
		width: 150px;
		height: 20px;
	}
}
