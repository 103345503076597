.FloorEntranceTitleCard {
	display: flex;
	justify-content: center;
	align-items: center;
	scroll-snap-align: start;

	.title-card {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 300px;
		height: 400px;
		text-align: center;
		font-family: 'Wigrum';
		color: #fff;

		@media only screen and (min-width: 500px) {
			width: 460px;
		}

		@media only screen and (min-width: $media-breakpoint-md) {
			width: 670px;
		}

		@media only screen and (min-width: $media-breakpoint-lg) {
			width: 950px;
		}

		@media only screen and (min-width: $media-breakpoint-xl) {
			width: 1264px;
		}

		@media only screen and (min-width: $media-breakpoint-xxl) {
			width: 1460px;
		}

		.unit-code {
			font-weight: 500;
			margin-bottom: 0.8em;
			font-size: 14px;

			@media (min-width: $media-breakpoint-md) {
				font-size: 18px;
			}

			@media (min-width: $media-breakpoint-xl) {
				font-size: 20px;
			}

			@media (min-width: $media-breakpoint-xxl) {
				font-size: 22px;
			}
		}

		.title {
			//font-size: 36px;
			font-weight: 500;
			margin-bottom: 15px;
			line-height: 1;

			//
			//@media only screen and (min-width: $media-breakpoint-md) {
			//	font-size: 64px;
			//}
			//
			//@media only screen and (min-width: $media-breakpoint-lg) {
			//	font-size: 70px;
			//}
			//
			//@media only screen and (min-width: $media-breakpoint-xl) {
			//	font-size: 90px;
			//	margin-bottom: 30px;
			//}
			//
			//@media only screen and (min-width: $media-breakpoint-xxl) {
			//	font-size: 120px;
			//}

			@include fluid-type(font-size, 320px, $media-breakpoint-xxl, 36px, 120px);
		}

		.tutors {
			margin-bottom: 25px;
			max-width: 300px;
			font-weight: 500;
			font-size: 14px;

			span {
				display: inline-block;
			}

			@media (min-width: $media-breakpoint-md) {
				max-width: 500px;
				font-size: 18px;
			}

			@media (min-width: $media-breakpoint-xl) {
				font-size: 20px;
			}

			@media (min-width: $media-breakpoint-xxl) {
				max-width: 900px;
				font-size: 26px;
			}
		}

		.pill-button:hover {
			background-color: $color-white;
			color: $color-black;
		}
	}
}
