.MainNav {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	opacity: 0;
	display: none;
	@extend .site-element-padding;

	ul {
		display: flex;
	}

	li {
		&:not(:last-child) {
			a {
				margin-right: 15px;
			}
		}

		&:last-child {
			a {
				margin-left: 15px;
			}
		}

		a {
			color: #ffffff;
			font-weight: 500;
			text-transform: capitalize;
			display: inline-block;

			&.active {
				text-decoration: underline;
				pointer-events: none;
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
